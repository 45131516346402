import React from "react"
import { Themed } from "theme-ui"

/**
 * Change the content to add your own bio
 */

export default function Bio() {
  return (
    <>
      Thanks for the read 👋
      <br />
      Your turn? <Themed.a href="/about">Write back</Themed.a> -
      {` `}
      I'd love to hear from you.
    </>
  )
}
